import { createSlice } from '@reduxjs/toolkit';

const initState = {
  primaryFilters: {},
  roundelFilter: 'Total',
  statusFilter: -1,
  showMachines: false,
};

// Overview slice deals with getting/setting the overview filters
export const overviewSlice = createSlice({
  // This is the namespace that the state is stored under
  // So when refering to overview state, it will be nested under state.overview
  name: 'overview',
  // Set up the initial state
  initialState: initState,
  // define reducers
  reducers: {
    setOverviewFilters: (state, action) => {
      state.primaryFilters = action.payload;
    },
    setOverviewShowMachines: (state, action) => {
      state.showMachines = action.payload;
    },
    setOverviewRoundelFilter: (state, action) => {
      state.roundelFilter = action.payload;
    },
    setOverviewStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
  },
});

export const selectOverviewPrimaryFilters = (state) => state.overview.primaryFilters;
export const selectOverviewShowMachines = (state) => state.overview.showMachines;
export const selectOverviewRoundelFilter = (state) => state.overview.roundelFilter;
export const selectOverviewStatusFilter = (state) => state.overview.statusFilter;

export const { setOverviewFilters, setOverviewShowMachines, setOverviewRoundelFilter, setOverviewStatusFilter } = overviewSlice.actions;

export default overviewSlice.reducer;
