import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import callApi from '../../services/callApi';

const initState = {};

export const getTelemetryData = createAsyncThunk(
  'telemetry/getData',
  async ([thingId, code, key, width], thunkApi) => {
    try {
      const token = thunkApi.getState().login.account.token;
      const data = await callApi(
        `api/telemetry/${thingId}/${code}/${key}/${width}`,
        token
      );
      if (data && data.root && data.root.measurements) {
        return {
          thingId,
          code,
          key,
          data,
        };
      } else {
        throw new Error(`no data returned for ${thingId}`);
      }
    } catch (err) {
      console.error(err);
      console.log(`There was a problem getting telemetry: ${err}`);
      throw err;
    }
  }
);

// Login slice deals with remembering the token and the logged in state.
export const telemetrySlice = createSlice({
  // This is the namespace that the state is stored under
  // So when refering to login state, it will be nested under state.login
  name: 'telemetry',
  // Set up the initial state in case we are already logged in.
  initialState: initState,
  reducers: {},
  extraReducers: {
    [getTelemetryData.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const { thingId, code, key } = data;
      const idx = `${thingId}/${code}/${key}`;
      state[idx] = data.root.measurements;
    },
    [getTelemetryData.rejected]: (state, action) => {
      console.error(action.payload.message);
      console.log(`Something went wrong ${action.payload.message}`);
    },
  },
});

export const selectTelemetry = (state) => state.telemetry;

export default telemetrySlice.reducer;
