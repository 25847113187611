import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import login from './slices/login';
import machine from './slices/machine';
import telemetry from './slices/telemetry';
import overview from './slices/overview';
import userPrefs from './slices/userPrefs';

const reducers = combineReducers({
  login,
  machine,
  telemetry,
  overview,
  userPrefs,
});

const store = configureStore({
  reducer: reducers,
});

export default store;
