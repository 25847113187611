import { createSlice } from '@reduxjs/toolkit';

const initState = {
  dateFormat: 'DD-MM-YYYY',
};

// userPrefs slice deals with gstoring userPrefs, will be refactored at some point to be stored in userPrefs panel against account in DB
export const userPrefsSlice = createSlice({
  name: 'userPrefs',
  initialState: initState,
  reducers: {
    setDateFormat: (state, action) => {
      state.dateFormat = action.payload;
    },
  },
});

export const selectDateFormat = (state) => state.userPrefs.dateFormat;

export const { setDateFormat } = userPrefsSlice.actions;

export default userPrefsSlice.reducer;
