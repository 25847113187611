import { useEffect } from 'react';

const App = () => {
  const redirectURL = 'https://oiview.oxinst.com/';
  useEffect(() => {
    window.location.href = redirectURL;
  }, []);

  return;
};

export default App;
