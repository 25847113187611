import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { locales } from './locales';

const resources = {
  'en-GB': {
    translations: require('./locales/en/translations.json'),
  },
  ja: {
    translations: require('./locales/ja/translations.json'),
  },
  'fr-FR': {
    translations: require('./locales/fr/translations.json'),
  },
  de: {
    translations: require('./locales/de/translations.json'),
  },
  ru: {
    translations: require('./locales/ru/translations.json'),
  },
  'zh-CN': {
    translations: require('./locales/zh-Hans/translations.json'),
  },
  'pt-BR': {
    translations: require('./locales/pt-BR/translations.json'),
  },
  ko: {
    translations: require('./locales/ko/translations.json'),
  },
  es: {
    translations: require('./locales/es/translations.json'),
  },
  it: {
    translations: require('./locales/it/translations.json'),
  },
  'zh-TW': {
    translations: require('./locales/zh-Hant/translations.json'),
  },
};

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    resources,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.languages = locales;

export default i18n;
