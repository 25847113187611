import { getAccount } from '../lib/auth';

const getAPIBaseEndpoint = (name) => {
  switch (name) {
    case 'nano':
      return process.env.REACT_APP_NANO_API_BASE_URL;
    default:
      return process.env.REACT_APP_API_BASE_URL;
  }
};

const callApi = async (endpoint, businessName = 'nano') => {
  const apiBase = getAPIBaseEndpoint(businessName);
  const url = new URL(`${apiBase}/${endpoint}`);
  const init = {
    headers: {},
  };

  // Parse the URL so we can add the security code that Azure gives us
  if (process.env['REACT_APP_CODE']) {
    url.searchParams.append('code', process.env.REACT_APP_CODE);
  }

  // Get account data and token, attach
  try {
    const accountData = await getAccount();
    const token = accountData.accessToken;
    init.headers['Authorization'] = `Bearer ${token}`;
  } catch (err) {
    console.error(err);
    throw err;
  }

  const res = await fetchWithTimeout(url, init);
  if (res.status >= 200 && res.status < 300) {
    try {
      const json = await res.json();
      if (!json) {
        console.error(res.body);
        throw new Error(`Got invalid json ${res.body}`);
      }
      return json;
    } catch (error) {
      console.error(error);
      throw error;
    }
  } else {
    let body = '';
    try {
      body = await res.text();
    } catch (err) {
      console.error(err);
      throw new Error('There was a problem with the backend');
    }

    if (body === 'Unauthorized') {
      console.log('Unauthorised user');
      throw new Error('You are not authorized to access this portal');
    }

    let errJson = {};
    try {
      errJson = JSON.parse(body);
    } catch (err) {
      console.error(err);
      // However there might have been a problem with the json decoding.
      throw err;
    }
    // isError indcates our own error from the middleware.
    if (errJson.isError) {
      console.error(errJson.error);
      throw errJson.error;
    }
  }
};

const fetchWithTimeout = async (url, init, timeout = 12000) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  try {
    const resp = await fetch(url, {
      ...init,
      signal: controller.signal,
    });
    return resp;
  } catch (err) {
    if (err.name === 'AbortError') {
      console.log('err', err);
      throw new Error('Backend timeout error');
    }
  } finally {
    clearTimeout(id);
  }
};

export default callApi;
