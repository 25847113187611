import * as Msal from 'msal';
import config from './client_config.js';

const myMSALObj = new Msal.UserAgentApplication(config.msalConfig);

export const getAccount = () => {
  const account = myMSALObj.getAccount();
  if (account) {
    const { preferedUsername } = account.idTokenClaims;
    const loginRequest = {
      ...config.loginRequest,
      login_hint: preferedUsername,
    }
    return myMSALObj
      .acquireTokenSilent(loginRequest)
      .catch((err) => {
        if (err) {
          return myMSALObj
            .acquireTokenPopup(loginRequest)
            .catch((err) => {
              console.error(
                `Interaction login failure: ${ err }`
              );
              return signIn()
                .catch((err) => {
                  console.error(`Last resort sign in failed: ${ err }`)
                  throw err;
                });
            }
            );
        }
      });
  } else {
    return signIn()
      .catch((err) => {
        console.error(`Last resort sign in failed: ${ err }`)
        throw err;
      })
      .then(() => {
        return myMSALObj
          .acquireTokenSilent(config.loginRequest)
          .catch((err) => {
            if (err) {
              return myMSALObj
                .acquireTokenPopup(config.loginRequest)
                .catch((err) => {
                  console.error(
                    `Interaction login failure: ${ err }`
                  );
                  return signIn();
                }
                );
            }
          });
      })
  }
};

export const signIn = async () => {
  try {
    await myMSALObj.loginPopup();
  } catch (err) {
    console.error(`There was a problem logging in: ${ err }`);
    throw err;
  }
};

export const signOut = () => myMSALObj.logout();

const Auth = {
  signIn,
  signOut
};

export default Auth;
