const msalConfig = {
  auth: {
    clientId: '2e7ddb32-2b8d-41e7-80b8-28d699489917',
    authority: 'https://login.microsoftonline.com/organizations/',
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
  scopes: ['api://2e7ddb32-2b8d-41e7-80b8-28d699489917/user_impersonation'],
  authority: 'https://login.microsoftonline.com/organizations/',
};

const Config = {
  msalConfig,
  loginRequest,
};

export default Config;
