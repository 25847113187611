import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import callApi from '../../services/callApi';

const initState = {
  fetchOk: false,
  currentMachine: null,
  lastSearchTerm: '',
  lastSearchType: '',
};

export const searchMachines = createAsyncThunk('machine/searchMachines', ([searchType, searchText], thunkApi) => {
  try {
    const token = thunkApi.getState().login.account.token;
    return callApi(`api/search/${searchType}/${searchText}`, token);
  } catch (err) {
    console.error(err);
    throw err;
  }
});

// Machine slice deals with getting the current machine
export const machineSlice = createSlice({
  // This is the namespace that the state is stored under
  // So when refering to machine state, it will be nested under state.machine
  name: 'machine',
  // Set up the initial state in case we are already logged in.
  initialState: initState,
  reducers: {
    setMachine: (state, action) => {
      state.currentMachine = action.payload;
    },
    clearMachine: (state) => {
      state.currentMachine = null;
    },
    setLastSearchTerm: (state, action) => {
      state.lastSearchTerm = action.payload;
    },
    setLastSearchType: (state, action) => {
      state.lastSearchType = action.payload;
    },
  },
  extraReducers: {
    [searchMachines.fulfilled]: (state, action) => {
      state.lastSearchTerm = action.payload.lastSearchTerm;
      state.lastSearchType = action.payload.lastSearchType;
      state.searchResult = action.payload;
    },
  },
});

export const selectCurrentMachine = (state) => state.machine.currentMachine;
export const selectSearchResults = (state) => state.machine.searchResult;
export const selectLastSearchTerm = (state) => state.machine.lastSearchTerm;
export const selectLastSearchType = (state) => state.machine.lastSearchType;

export const { clearMachine, setMachine, setLastSearchTerm, setLastSearchType } = machineSlice.actions;

export default machineSlice.reducer;
