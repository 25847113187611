import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccount, signIn } from '../../lib/auth';

const localStorageKey = 'loginDetails';

const loggedOutState = {
  loggedIn: false,
  account: {},
};

const parseAccountData = (accountData) => ({
  name: accountData.account.name,
  userName: accountData.account.userName,
  token: accountData.accessToken,
});

// This is an async action as we are waiting on the user response when signing in
export const doLogin = createAsyncThunk('login/doLogin', async () => {
  try {
    await signIn();
  } catch (err) {
    console.log('There was a problem logging in');
    throw err;
  }
  try {
    const accountData = parseAccountData(await getAccount());
    return accountData;
  } catch (err) {
    console.log('There was a problem getting the account token data');
    throw err;
  }
});

// Rehydrates a saved state.
export const checkLogin = createAsyncThunk('login/checkLogin', async () => {
  try {
    const accountData = await getAccount();
    const account = parseAccountData(accountData);
    return {
      loggedIn: true,
      account,
    };
  } catch (err) {
    return loggedOutState;
  }
});

// Login slice deals with remembering the token and the logged in state.
export const loginSlice = createSlice({
  // This is the namespace that the state is stored under
  // So when refering to login state, it will be nested under state.login
  name: 'login',
  // Set up the initial state in case we are already logged in.
  initialState: loggedOutState,
  reducers: {
    doLogout: (state) => {
      window.localStorage.removeItem(localStorageKey);
      state.loggedIn = false;
      state.account = {};
    },
  },
  extraReducers: {
    [doLogin.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.account = action.payload;
      window.localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          loggedIn: true,
        })
      );
    },
    [checkLogin.fulfilled]: (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      state.account = action.payload.account;
    },
  },
});

// Export the login, logout actions
export const { doLogout } = loginSlice.actions;

// Export selectors for the values
// Remember that login is nested under state.login
export const selectAccount = (state) => state.login.account;
export const selectLoggedIn = (state) => state.login.loggedIn;

export default loginSlice.reducer;
